import './global/js/util/modernizr';
import './global/js/util/polyfills';
import './style.scss';
import './global/js/core/core';

/*
 * Animations
 */
// import scrollSkewer from './animations/scroll-skewer';
import cursorEffects from './global/js/animations/cursor-effects';
import staggerAnimation from './global/js/animations/gsap/stagger-text';
import parallaxScroll from './global/js/animations/parallax-light';
import progressBar from './global/js/animations/progress';
// import letterEffects from './global/js/animations/splittext';
import letterEffectTitlesB from './global/js/animations/title-b-splittext';
import letterEffectTitlesC from './global/js/animations/title-c-splittext';
/*
 * Misc
 */
import dropdownInit from './global/js/misc/dropdown/dropdown-init';
import externalLinks from './global/js/misc/external-links/external-links';
import giveNowButton from './global/js/misc/give-now/give-now';
import customMicromodalCall from './global/js/misc/micromodal/micromodal';
import saveInCalendarInit from './global/js/misc/save-in-calendar/save-in-calendar-init';
import swiperInit from './global/js/misc/swiper/swiper-init';
import tableInit from './global/js/misc/table/table-init';
/*
 * Atoms
 */
import floatingCta from './patterns/01-atoms/buttons/button-floating-cta-a/button-floating-cta-a-1/button-floating-cta-a-1';
import scrollToTop from './patterns/01-atoms/misc/scroll-to-top-a/scroll-to-top-a-1/scroll-to-top-a-1';
/*
 * Molecules
 */
import showAccordion from './patterns/02-molecules/accordions/accordion-a/accordion-a-1';
import showAccordionB from './patterns/02-molecules/accordions/accordion-a/accordion-a-1';
import debugControls from './patterns/02-molecules/admin/debug-controls-a/debug-controls-a-1';
import multiselectDropdown from './patterns/02-molecules/dropdowns/terms-multiselect-a/terms-multiselect-a-1';
import galleryA from './patterns/02-molecules/galleries/gallery-a/gallery-a-1';
import mapBlock from './patterns/02-molecules/maps/map-a/map-a-1';
import modal from './patterns/02-molecules/modals/modal-a/modal-a-1';
import modalVideoA1 from './patterns/02-molecules/modals/modal-video-a/modal-video-a-1';
import { hamburger } from './patterns/02-molecules/nav/hamburger-a/hamburger-a-1';
import navigateCategory from './patterns/02-molecules/nav/nav-category-a/nav-category-a-1';
import navFooter from './patterns/02-molecules/nav/nav-footer-a/nav-footer-a.js';
import navMob from './patterns/02-molecules/nav/nav-mobile-a/nav-mobile-a-1';
/*
 * Plop Entry (do not remove)
 */
/// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
/// /IMPORT FROM PLOP
import { postHeadingNavigationA1 } from './patterns/02-molecules/nav/post-heading-navigation-a/post-heading-navigation-a-1.js';
import searchBar from './patterns/02-molecules/search/search-bar-a/search-bar-a-1';
import { slideOut } from './patterns/02-molecules/slide-outs/slide-out-a/slide-out-a-1';
import sliderImages from './patterns/02-molecules/sliders/slider-images-a/slider-images-a-1';
import sliderTestimonials from './patterns/02-molecules/sliders/slider-testimonials-a/slider-testimonials-a-1';
import socialShare from './patterns/02-molecules/social/social-share-a/social-share-a-1';
import tabs from './patterns/02-molecules/tabs/tabs-a/tabs-a-1';
import widgetTranslation from './patterns/02-molecules/widgets/widget-translation/widget-translation';
/*
 * Organisms
 */
import calendarA1 from './patterns/03-organisms/repeatable-custom/calendar-a/calendar-a-1/calendar-a-1';
import feedA1 from './patterns/03-organisms/repeatable-custom/feed-a/feed-a-1/feed-a-1';
import filterType1 from './patterns/03-organisms/repeatable-custom/filter-a/filter-a-1/filter-a-1';
import menuA1 from './patterns/03-organisms/repeatable-custom/menu-a/menu-a-1/menu-a-1';
import seasonsTypeA1 from './patterns/03-organisms/repeatable-custom/seasons-a/seasons-a-1/seasons-a-1';
import storyTellingB1 from './patterns/03-organisms/repeatable-custom/storytelling-b/storytelling-b-1/storytelling-b-1';
import timelineA1 from './patterns/03-organisms/repeatable-custom/timeline-a/timeline-a-1/timeline-a-1';
import headerInit from './patterns/03-organisms/single/header/header-init';
import hero1 from './patterns/03-organisms/single/hero/hero-1/hero-1';
import hero2 from './patterns/03-organisms/single/hero/hero-2/hero-2';
import hero3 from './patterns/03-organisms/single/hero/hero-3/hero-3';
import hero4 from './patterns/03-organisms/single/hero/hero-4/hero-4';
import hero5 from './patterns/03-organisms/single/hero/hero-5/hero-5';
import heroAuto from './patterns/03-organisms/single/hero/hero-auto-1/hero-auto-1';
import heroAuto2 from './patterns/03-organisms/single/hero/hero-auto-2/hero-auto-2';
import heroAuto3 from './patterns/03-organisms/single/hero/hero-auto-3/hero-auto-3';

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
    showAccordion,
    showAccordionB,
    sliderImages,
    sliderTestimonials,
    navigateCategory,
    navFooter,
    searchBar,
    scrollToTop,
    floatingCta,
    socialShare,
    hamburger,
    hero1,
    hero2,
    hero3,
    hero4,
    hero5,
    heroAuto,
    heroAuto2,
    heroAuto3,
    slideOut,
    modal,
    modalVideoA1,
    debugControls,
    tabs,
    timelineA1,
    mapBlock,
    galleryA,
    filterType1,
    multiselectDropdown,
    progressBar,
    /// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
    /// /MODULES FROM PLOP
    postHeadingNavigationA1,
    seasonsTypeA1,
    storyTellingB1,
    menuA1,
    calendarA1,
    feedA1,
};

/*
 * Fire JavaScript functions by if the associated "data-module" exists on the page.
 */
const calledModulesNames = [];
modules.instantiate = function (elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in modules) {
        if ($this.attr('data-initialized') === 'true') {
            return;
        }
        new modules[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw `Module '${module}' not found`;
    }
};

jQuery(document).ready(() => {
    widgetTranslation($);
    navMob($);
    externalLinks($);
    customMicromodalCall($);

    headerInit();
    dropdownInit();
    swiperInit();
    saveInCalendarInit();
    tableInit();
    giveNowButton();

    staggerAnimation();
    /*
     * Loops through component functions that should only run if the component exists in the ajax container or page.
     * The actual check is performed through "data-module";
     */
    $('[data-module]').each(function () {
        if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
            modules.instantiate(this);
            calledModulesNames.push($(this).data('module'));
        }
    });

    modal($);

    /*
     * GSAP based animations. Hide if not used.
     */
    // cursorEffects($);
    // letterEffects($);
    letterEffectTitlesB($);
    letterEffectTitlesC($);
    // scrollSkewer($);
});

jQuery(window).scroll(() => {
    parallaxScroll($);
});
