import Swiper from 'swiper';

const componentClass = 'o-storytelling-b-1';
const edgeResolution = 1024;
const blocks = document.querySelectorAll(`.${componentClass}`);

function storyTellingB1() {
    blocks.forEach((block) => {
        initializeToggler(block);
        initializeSwiper(block);
    });
}

function initializeToggler(container) {
    // Get all elements with the attribute data-index
    const elements = container.querySelectorAll('[data-index]');
    // Attach a click event listener to each element
    elements.forEach((element) => {
        element.addEventListener('click', () => {
            elements.forEach((el) => {
                if (el !== element) {
                    el.classList.remove('opacity-100');
                    el.classList.add('opacity-50');
                }
            });
            element.classList.add('opacity-100');
            element.classList.remove('opacity-50');
            const dataIndexValue = element.getAttribute('data-index');
            // Get the element with matching data-value attribute
            const targetElement = container.querySelector(
                `[data-value="${dataIndexValue}"]`,
            );
            if (targetElement) {
                const targetElements =
                    container.querySelectorAll('[data-value]');
                // Hide all elements with different data-value attribute
                targetElements.forEach((el) => {
                    if (el.getAttribute('data-value') !== dataIndexValue) {
                        el.classList.remove('opacity-100');
                        el.classList.add('opacity-0');
                    }
                });
                // Show the target element
                targetElement.classList.remove('opacity-0');
                targetElement.classList.add('opacity-100');
            }
        });
    });
}

// Function to initialize the Swiper sliders
function initializeSwiper(container) {
    const slidersContainers = container.querySelectorAll(
        `.${componentClass}__swiper-container`,
    );
    // Check if there are no slider containers
    if (!slidersContainers.length) {
        return;
    }
    // Array to store the Swiper instances
    let sliders = [];
    // Initialize the sliders if the window width is larger or equal to the edge resolution
    if (window.innerWidth <= edgeResolution) {
        initSliders();
    }

    function initSliders() {
        slidersContainers.forEach((swiperContainer, index) => {
            if (!sliders[index]) {
                sliders[index] = new Swiper(swiperContainer, {
                    effect: 'fade',
                    allowTouchMove: true,
                    loop: true,
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                    centeredSlides: true,
                    on: {
                        slideChange: function () {
                            const currentSlide = this.slides[this.activeIndex];
                            const textWrappers = container.querySelectorAll(
                                '.o-storytelling-b-1__text-wrapper',
                            );
                            textWrappers.forEach((textWrapper) => {
                                if (textWrapper.parentNode !== currentSlide) {
                                    textWrapper.classList.remove('opacity-100');
                                    textWrapper.classList.add('opacity-0');
                                }
                            });
                            const currentSlideTextWrapper =
                                currentSlide.querySelector(
                                    '.o-storytelling-b-1__text-wrapper',
                                );
                            if (
                                currentSlideTextWrapper.classList.contains(
                                    'opacity-0',
                                )
                            ) {
                                currentSlideTextWrapper.classList.remove(
                                    'opacity-0',
                                );
                                currentSlideTextWrapper.classList.add(
                                    'opacity-100',
                                );
                            }
                        },
                    },
                });
            }
        });
    }
}

export default storyTellingB1;
