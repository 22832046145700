/**
 * Export the default function.
 *
 * @param {module} module The module object.
 */
export default (module) => {
    /**
     * Get the `.js-play-latest-episode-btn`
     */
    const playLatestEpisodeBtn = module.querySelector(
        '.js-play-latest-episode-btn',
    );

    /**
     * Handle the click event on the `.js-play-latest-episode-btn`
     *
     * @param {Event} event The click event.
     */
    const processPlayLatestEpisodeBtnClick = (event) => {
        // Get the latest episodes slides.
        const latestEpisodesSlides = document.querySelectorAll(
            '.o-seasons-a-1:first-of-type .swiper-slide:last-of-type',
        );

        // Play trailer and Return if there is no elements
        if (!latestEpisodesSlides.length) {
            var trailerButton = document.querySelector('.js-play-trailer');
            if (trailerButton) {
                trailerButton.click();
            } else {
                console.log('Trailer link not found');
            }
            return;
        }

        // Prevent the default action
        event.preventDefault();

        // Get the video element in the latest episode slide
        const video = latestEpisodesSlides[0].querySelector(
            '[data-micromodal-trigger]',
        );

        // Return if there is no video element
        if (!video) {
            return;
        }

        // Create a new `click` event object.
        const customClickEvent = new Event('click');

        // Dispatch the event to the video element.
        video.dispatchEvent(customClickEvent);
    };

    // If the `.js-play-latest-episode-btn` exists, add an event listener to it.
    if (playLatestEpisodeBtn) {
        playLatestEpisodeBtn.addEventListener(
            'click',
            processPlayLatestEpisodeBtnClick,
        );
    }
};
