import AOS from 'aos';
import Swiper from 'swiper';

function seasonsTypeA1() {
    initializeSwiper();
    initializeAccordion();
}

const componentClass = 'o-seasons-a-1';
const edgeResolution = 768;
// Store the previous window width for resizing detection
let prevWindowWidth = window.innerWidth;
// Add a resize event listener to handle window resizing
window.addEventListener('resize', (event) => {
    let customEvent = null;
    const resizeDirection = window.innerWidth > prevWindowWidth;
    // Check if the window size transitions to tablet
    if (resizeDirection) {
        if (
            prevWindowWidth < edgeResolution &&
            window.innerWidth >= edgeResolution
        ) {
            // Create a custom event for becoming tablet
            customEvent = new CustomEvent('becomesTablet', {
                bubbles: true,
                cancelable: true,
            });
        }
    } else {
        // Check if the window size transitions to mobile
        if (
            prevWindowWidth >= edgeResolution &&
            window.innerWidth < edgeResolution
        ) {
            // Create a custom event for becoming mobile
            customEvent = new CustomEvent('becomesMobile', {
                bubbles: true,
                cancelable: true,
            });
        }
    }
    // Dispatch the custom event if it was created
    if (customEvent) {
        document.dispatchEvent(customEvent);
    }
    // Update the previous window width
    prevWindowWidth = window.innerWidth;
});
// Function to initialize the Swiper sliders
function initializeSwiper() {
    const slidersContainers = document.querySelectorAll(
        `.${componentClass}__swiper-container`,
    );
    // Check if there are no slider containers
    if (!slidersContainers.length) {
        return;
    }
    // Array to store the Swiper instances
    let sliders = [];
    // Initialize the sliders if the window width is larger or equal to the edge resolution
    if (window.innerWidth >= edgeResolution) {
        initSliders();
    }

    document.addEventListener('becomesTablet', (event) => {
        initSliders();
    });
    document.addEventListener('becomesMobile', (event) => {
        destroySliders();
    });

    function initSliders() {
        slidersContainers.forEach((swiperContainer, index) => {
            const buttonNext = swiperContainer.querySelector(
                '.js-swiper-btn-next',
            );
            const buttonPrev = swiperContainer.querySelector(
                '.js-swiper-btn-prev',
            );
            if (!sliders[index]) {
                sliders[index] = new Swiper(swiperContainer, {
                    effect: 'fade',
                    allowTouchMove: true,
                    loop: false,
                    slidesPerView: 'auto',
                    spaceBetween: 40,
                    navigation: {
                        prevEl: buttonPrev,
                        nextEl: buttonNext,
                    },
                });
            }
        });
    }
    // Function to destroy the sliders
    function destroySliders() {
        sliders.forEach((swiper) => {
            if (swiper) {
                swiper.destroy();
            }
        });
        sliders = [];
    }
}
// Function to initialize the accordion functionality
function initializeAccordion() {
    // Check if the window width is smaller than the edge resolution
    if (window.innerWidth < edgeResolution) {
        initAccordion();
    } else {
        destroyAccordion();
    }

    document.addEventListener('becomesTablet', (event) => {
        destroyAccordion();
        showAccordionBlocks();
    });
    document.addEventListener('becomesMobile', (event) => {
        initAccordion();
    });

    function initAccordion() {
        $(`.${componentClass} .js-accordion-header`).each(function (index) {
            let content = $(this).next('.js-accordion-content');
            let arrow = $(this).find('.js-arrow-accordion');
            $(this)
                .off('click')
                .on('click', function () {
                    if (content.is(':visible')) {
                        content.slideUp('fast', () => {
                            AOS.refresh();
                        });
                        // Remove the class to reset the arrow rotation
                        arrow.removeClass('-rotate-90');
                        arrow.addClass('rotate-90');
                    } else {
                        $('.js-accordion-content').slideUp();
                        // Remove the class from all arrows
                        $('.js-arrow-accordion').removeClass('-rotate-90');
                        // Remove the class from all arrows
                        $('.js-arrow-accordion').addClass('rotate-90');
                        content.slideDown('fast', () => {
                            AOS.refresh();
                        });
                        // Add the class to rotate the arrow
                        arrow.addClass('-rotate-90');
                    }
                });

            if (index > 0) {
                content.hide();
                arrow.removeClass('-rotate-90');
                arrow.addClass('rotate-90');
            }
        });
    }
    function destroyAccordion() {
        $(`.${componentClass} .js-accordion-header`).off('click');
    }
    function showAccordionBlocks() {
        const accordeonBlocks = document.querySelectorAll(
            `.${componentClass}__column.js-accordion-content`,
        );
        accordeonBlocks.forEach((block) => {
            block.style.display = 'block';
        });
    }
}

export default seasonsTypeA1;
