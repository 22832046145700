/**
 * Initializes the "Give Now" button functionality.
 * This function hides the button when a specific class is added to the header
 * and implements smooth scrolling behavior.
 *
 * @returns {void}
 */
function giveNowButton() {
    let body;
    let header;
    let button;

    /**
     * Executes the logic when the header class changes.
     *
     * @param {MutationRecord[]} mutationsList - List of mutations.
     * @param {MutationObserver} observer - Mutation observer instance.
     * @returns {void}
     */
    function handleHeaderClassChange(mutationsList, observer) {
        for (const mutation of mutationsList) {
            if (
                mutation.type === 'attributes' &&
                mutation.attributeName === 'class'
            ) {
                // Check if the header has the class o-header--hamburger-active
                if (header.classList.contains('o-header--hamburger-active')) {
                    // Hide the button
                    button.style.display = 'none';
                } else {
                    // Show the button
                    button.style.display = 'block';
                }
            }
        }
    }

    /**
     * Executes the logic when the body class changes.
     *
     * @param {MutationRecord[]} mutationsList - List of mutations.
     * @param {MutationObserver} observer - Mutation observer instance.
     * @returns {void}
     */
    function handleBodyClassChange(mutationsList, observer) {
        for (const mutation of mutationsList) {
            if (
                mutation.type === 'attributes' &&
                mutation.attributeName === 'class'
            ) {
                // Check if the body has the class you want to monitor
                if (body.classList.contains('lg-on')) {
                    // Hide the button
                    button.style.display = 'none';
                } else {
                    // Show the button
                    button.style.display = 'block';
                }
            }
        }
    }

    // When the window has fully loaded, execute "Give Now" button functionality
    window.onload = () => {
        // Get the iframe element with the ID 'XFEFDVPJ'
        const iframe = document.getElementById('XFEFDVPJ');

        if (iframe) {
            // Get the document inside the iframe (cross-browser compatibility)
            const innerDoc =
                iframe.contentDocument || iframe.contentWindow.document;

            // Find the button with the class 'js-focus-visible' inside the iframe
            button = innerDoc.querySelector('.js-focus-visible');
            header = document.querySelector('.o-header-1');
            body = document.querySelector('.l-body');

            // Create a MutationObserver to watch for changes in the header's class
            const headerObserver = new MutationObserver(
                handleHeaderClassChange,
            );
            headerObserver.observe(header, { attributes: true });

            // Create a MutationObserver to watch for changes in the body's class
            const bodyObserver = new MutationObserver(handleBodyClassChange);
            bodyObserver.observe(body, { attributes: true });

            // Set a tolerance for smooth scrolling behavior
            const tolerance = 15;

            // Initialize variables to keep track of scrolling state
            let isScrolledDown = false;
            let lastScrollY = window.scrollY;

            // Add a scroll event listener to the window
            window.addEventListener('scroll', () => {
                // Get the current vertical scroll position
                const scrollY = window.scrollY;

                // Calculate the change in scroll position since the last scroll event
                const deltaY = scrollY - lastScrollY;

                // Check if the user is scrolling down and the button is not already hidden
                if (deltaY > tolerance && !isScrolledDown) {
                    // Scroll down behavior

                    // Apply a transition for smooth animation
                    button.style.transition = iframe.style.transition =
                        'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)';

                    // Move the button off the screen vertically
                    button.style.transform = 'translate(0, 100%)';
                    // Move the iframe which overlaps button off the screen vertically
                    iframe.style.transform =
                        'translate(100%, -148px) rotate(-90deg)';

                    // Hide any overflow content
                    button.style.overflow = 'hidden';

                    // Update the scrolling state
                    isScrolledDown = true;
                } else if (deltaY < -tolerance && isScrolledDown) {
                    // Scroll up behavior

                    // Apply a transition for smooth animation
                    button.style.transition = iframe.style.transition =
                        'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)';

                    // Move the button back onto the screen vertically
                    button.style.transform = 'translate(0, 0)';
                    // Move the iframe which overlaps button back onto the screen vertically
                    iframe.style.transform =
                        'translate(0, -148px) rotate(-90deg)';

                    // Show overflow content
                    button.style.overflow = 'visible';

                    // Update the scrolling state
                    isScrolledDown = false;
                }

                // Update the last scroll position for the next scroll event
                lastScrollY = scrollY;
            });

            // Add initial styling to the button
            button.style.transition = 'all 0.3s ease-in-out';
            button.style.transform = 'translateX(0)';
            button.style.overflow = 'visible';
        }
    };
}

export default giveNowButton;
