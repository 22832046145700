/**
 * Applies letter effect animation to Title C.
 * @function letterEffectTitlesC
 */

import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SplitText from '../vendors/gsap-splittext/splittext';

function letterEffectTitlesC() {
    gsap.registerPlugin(ScrollTrigger);

    // Assign variable to the element that will be animated.
    const element = $('.js-title-c-anim-split-text');

    // Checking if the element exists on the page. If so, proceed.
    if (element.length) {
        // Making an array of elements. As multiple (to-be animated) elements could exist on a page.
        const elements = gsap.utils.toArray('.js-title-c-anim-split-text');

        // Looping through the array. Firing a timeline for each
        elements.forEach((item) => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    toggleActions: 'play none none none',
                    end: 'bottom center',
                },
            });

            // Creating a splittext object for each letter/word
            const elementSplit = new SplitText(item, {
                tag: 'span',
                type: 'chars,words',
            });

            const elementWords = elementSplit.chars;

            // The title line
            const elementLine = item.previousElementSibling;

            // The title arrow icon
            const elementArrow = item.nextElementSibling;

            // Animate item autoAlpha from 0 to 1
            tl.fromTo(
                item,
                {
                    autoAlpha: 0,
                },
                {
                    duration: 0.2,
                    autoAlpha: 1,
                },
            );

            // Animate the title line
            tl.fromTo(
                elementLine,
                {
                    scaleX: 0,
                },
                {
                    duration: 1.6,
                    scaleX: 1,
                    ease: Power4.easeInOut,
                    transformOrigin: 'right left',
                },
                '>+0.3',
            );

            // We're finally ready to animate! Staggering each letter or word
            tl.from(
                elementWords,
                {
                    duration: 0.8,
                    yPercent: 100,
                    autoAlpha: 0,
                    ease: Power4.easeInOut,
                    stagger: 0.05,
                },
                '>-0.3',
            );

            // Animate the title arrow icon
            tl.fromTo(
                elementArrow,
                {
                    x: -15,
                    autoAlpha: 0,
                },
                {
                    duration: 0.8,
                    x: 0,
                    autoAlpha: 1,
                    ease: Power4.easeInOut,
                },
                '>-0.5',
            );
        });
    }
}

export default letterEffectTitlesC;
