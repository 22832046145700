/**
 * Applies animation to Timeline A elements.
 * @function animateTimelineA
 */

import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

/**
 * Animates the Timeline A component.
 */
function animateTimelineA() {
    gsap.registerPlugin(ScrollTrigger);

    // Select all the Timeline A components
    const timelineAElements = document.querySelectorAll('.o-timeline-a-1');

    // Looping through the array. Firing a timeline for each timelineA element
    timelineAElements.forEach((timelineAElement) => {
        // Assign variables to the title elements and line within the current Timeline A component
        const titleB = timelineAElement.querySelector(
            '.o-timeline-a-1__title-b .a-title-b__text',
        );
        const titleC = timelineAElement.querySelector(
            '.o-timeline-a-1__title-c .a-title-c__text',
        );
        const timelineSeparatorLine = timelineAElement.querySelector(
            '.o-timeline-a-1__line-wrapper .a-separator-line',
        );

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: timelineAElement,
                toggleActions: 'play none none none',
                end: 'bottom center',
            },
        });

        // Creating a splittext object for each letter/word for titleB
        const titleBSplit = new SplitText(titleB, {
            tag: 'span',
            type: 'chars,words',
        });

        // Creating a splittext object for each letter/word for titleC
        const titleCSplit = new SplitText(titleC, {
            tag: 'span',
            type: 'chars,words',
        });

        const titleBWords = titleBSplit.chars;
        const titleCWords = titleCSplit.chars;

        // Animate titleB autoAlpha from 0 to 1
        tl.fromTo(
            titleB,
            {
                autoAlpha: 0,
            },
            {
                duration: 0.25,
                autoAlpha: 1,
            },
            '>0.1',
        );

        // Staggering each letter or word for titleB
        tl.from(
            titleBWords,
            {
                duration: 0.9,
                yPercent: 100,
                autoAlpha: 0,
                ease: Power4.easeInOut,
                stagger: 0.05,
            },
            '>0.1',
        );

        // Animate the separator line
        tl.fromTo(
            timelineSeparatorLine,
            {
                autoAlpha: 0,
                scaleX: 0,
            },
            {
                duration: 1.8,
                autoAlpha: 1,
                scaleX: 1,
                ease: Power4.easeInOut,
            },
            '>-1',
        );

        // Animate titleC autoAlpha from 0 to 1
        tl.fromTo(
            titleC,
            {
                autoAlpha: 0,
            },
            {
                duration: 0.25,
                autoAlpha: 1,
            },
            '>-1',
        );

        // Staggering each letter or word for titleC
        tl.from(
            titleCWords,
            {
                duration: 0.9,
                yPercent: 100,
                autoAlpha: 0,
                ease: Power4.easeInOut,
                stagger: 0.05,
            },
            '>-0.25',
        );
    });
}

export default animateTimelineA;
