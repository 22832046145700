/**
 * Search Toggle
 *
 * Runs the functionality of clicking a search icon and a search bar appears.
 */

/**
 * Initializes the search bar functionality.
 */
function searchBar() {
    const searchBar = $('.m-search-bar');
    const algoliaAutocomplete = $('.algolia-autocomplete');
    let isSearchBarActive = searchBar.hasClass('m-search-bar--active');

    /**
     * Handles the click event of the search toggle button.
     */
    $('.js-header-search-toggle').on('click', function () {
        if (!isSearchBarActive) {
            $(this).toggleClass('a-button-search--active');
            searchBar.addClass('m-search-bar--active');
            searchBar.slideDown(400, function () {
                // Animation complete callback
                $('body').addClass('h-scroll-lock');
            });

            if (algoliaAutocomplete.css('display') === 'block') {
                algoliaAutocomplete.css('display', 'none');
            }

            if ($('.m-search-bar').hasClass('m-search-bar--active')) {
                setTimeout(function () {
                    $('.m-search-bar__search-field').focus();
                }, 600);
            }
        }

        isSearchBarActive = !isSearchBarActive; // Toggle search bar active state
    });

    /**
     * Handles the click event of the mobile search toggle button.
     */
    $('.js-header-search-toggle-mobile').on('click', function () {
        $('.js-slide-out-search-wrapper-mob').toggleClass(
            'c-slide-out__search-wrapper--active',
        );
        setTimeout(function () {
            $('.js-search-field-mob').focus();
        }, 600);
    });

    /**
     * Handles the keyup event of the search field.
     * @param {Event} e - The keyup event object.
     */
    $('.m-search-bar__search-field').keyup(function (e) {
        if ($(this).val().length) {
            $('.m-popular-searches').css('display', 'none');
            $(this).closest('.m-search-bar--1').addClass('m-search-bar--focus');
        } else {
            $('.m-popular-searches').css('display', 'block');
            $(this)
                .closest('.m-search-bar--1')
                .removeClass('m-search-bar--focus');
        }
    });

    /**
     * Handles the click event of the search bar close button.
     */
    $('.m-search-bar__close-btn').on('click', function () {
        $(this).siblings('.m-search-bar__search-field').val('').focus();
        $('.m-popular-searches').css('display', 'block');
        $(this).closest('.m-search-bar--1').removeClass('m-search-bar--focus');
        algoliaAutocomplete.css('display', 'none');
    });

    /**
     * Closes the search bar when clicking outside.
     * @param {Event} e - The mouseup event object.
     */
    $(document).on('mouseup', function (e) {
        if (
            !searchBar.is(e.target) &&
            searchBar.has(e.target).length === 0 &&
            searchBar.hasClass('m-search-bar--active')
        ) {
            $('.m-search-bar').slideUp(400, function () {
                // Animation complete callback
                searchBar.removeClass('m-search-bar--active');
                $('body').removeClass('h-scroll-lock');
                isSearchBarActive = false; // Update search bar active state
            });
        }
    });
}

export default searchBar;
