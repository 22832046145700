import { Calendar, createPlugin, sliceEvents } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import axios from 'axios';
import mixitup from 'mixitup';
import tippy, { roundArrow } from 'tippy.js';

class CalendarA1 {
    static SLOTS_ENDPOINT = '/wp-json/dod/v1/slot';
    static COMPONENT_CLASS = 'o-calendar-a-1';
    /**
     * Two mods are available: link and tooltip
     * @type {string}
     */
    static EVENT_CLICK_MODE = 'link';

    /**
     * Maximum window width for classifying a mode as 'mobile'.
     * @type {number}
     */
    static MAX_MOBILE_RESOLUTION = 767;

    /**
     * The main block container
     * @type {HTMLElement}
     */
    container;

    /**
     * Block that is being used for initializing the FullCalendar instance
     * @type {HTMLElement}
     */
    grid;

    /**
     * Calendar block pagination 'Prev' button
     * @type {HTMLElement}
     */
    paginationPrev;

    /**
     * Calendar block pagination 'Next' button
     * @type {HTMLElement}
     */
    paginationNext;

    /**
     * The 'List/Month View' button
     * @type {HTMLElement}
     */
    viewButton;

    /**
     * Button to open/hide filters block
     * @type {HTMLElement}
     */
    filterButton;

    /**
     * Filters Block
     * @type {HTMLElement}
     */
    filtersBlock;

    /**
     * The Calendar Block loader element
     * @type {HTMLElement}
     */
    loader;

    /**
     * Current instance of the Calendar Class (FullCalendar app)
     * @type {Calendar}
     */
    calendar;

    /**
     * Button HTMLElement that show more/less some filter lists
     * @type {HTMLCollection}
     */
    showMoreButtons;

    /**
     * Search button
     * @type {HTMLElement}
     */
    applyFiltersButton;

    /**
     * Clear Filters button
     * @type {HTMLElement}
     */
    clearFiltersButton;

    /**
     * Inputs which are used as block filters
     * @type {HTMLCollection}
     */
    filterInputs;

    /**
     * Block filters crumbs HTML Collection
     * @type {HTMLCollection}
     */
    filterCrumbs;

    /**
     * Mixer Instance for filters crumbs
     * @type {Mixer}
     */
    mixer;

    /**
     * Container of filters crumbs
     * @type {HTMLElement}
     */
    crumbsContainer;

    /**
     * An Observer Class instance
     * @type {Observer}
     */
    observer;

    /**
     * Represents the current filter values used for fetching Event Slots
     * @typedef {Object}
     * @property {string} [date_from]        - Dates range starting day in format 'YYYY-MM-DD'.
     * @property {string} [date_to]          - Dates range last day in format 'YYYY-MM-DD'.
     * @property {string} [category]         - A comma separated list of categories IDs.
     * @property {string} [location]         - A comma separated list of locations IDs.
     * @property {string} [search]           - Search field value.
     * @property {boolean} [include_expired] - Whether we need to fetch expired event slots or not
     * @property {number} [per_page]         - Maximum number of event slots to be fetch in a single query
     */
    state = {
        date_from: {
            default: '',
            value: '',
            includeToUrl: true,
            urlKey: 'date_from',
            includeToFetch: true,
            fetchKey: 'date_from',
        },
        date_to: {
            default: '',
            value: '',
            includeToUrl: true,
            urlKey: 'date_to',
            includeToFetch: true,
            fetchKey: 'date_to',
        },
        category: {
            default: '',
            value: '',
            includeToUrl: true,
            urlKey: 'category',
            includeToFetch: true,
            fetchKey: 'event-type',
        },
        location: {
            default: '',
            value: '',
            includeToUrl: true,
            urlKey: 'location',
            includeToFetch: true,
            fetchKey: 'location',
        },
        s: {
            default: '',
            value: '',
            includeToUrl: true,
            urlKey: 'search',
            includeToFetch: true,
            fetchKey: 's',
        },
        include_expired: {
            default: true,
            value: true,
            includeToUrl: false,
            urlKey: '',
            includeToFetch: true,
            fetchKey: 'include_expired',
        },
        per_page: {
            default: 100,
            value: 100,
            includeToUrl: false,
            urlKey: '',
            includeToFetch: true,
            fetchKey: 'per_page',
        },
        view: {
            default: 'calendar',
            value: 'calendar',
            includeToUrl: true,
            urlKey: 'view',
            includeToFetch: false,
            fetchKey: '',
        },
    };

    /**
     * An array of Event Slots for received from the last fetching
     * @typedef {Object[]}
     * @property {string} [id]
     * @property {string} [groupId]
     * @property {boolean} [allDay]
     * @property {string} [title]
     * @property {string} [start]
     * @property {string} [url]
     * @property {boolean} [editable]
     * @property {boolean} [startEditable]
     * @property {boolean} [durationEditable]
     * @property {boolean} [resourceEditable]
     * @property {string} [display]
     * @property {boolean} [overlap]
     * @property {string} [backgroundColor]
     * @property {string} [borderColor]
     * @property {string} [textColor]
     * @property {Object} [extendedProps]
     */
    calendarSlots = [];

    /**
     * Array of filters items that can be selected by a user and it affects the REST API calls
     * @type {[{name: string, tag: string, type: string, queryParam: string, includedInMixer: boolean}]}
     */
    filtersItemsMap = [
        {
            name: 'event-type',
            tag: 'input',
            type: 'checkbox',
            queryParam: 'category',
            includedInMixer: true,
        },
        {
            name: 'location',
            tag: 'input',
            type: 'checkbox',
            queryParam: 'location',
            includedInMixer: true,
        },
        {
            name: 'search',
            tag: 'input',
            type: 'text',
            queryParam: 's',
            includedInMixer: false,
        },
    ];

    /**
     * Block that contains templates blocks
     * @type {HTMLElement}
     */
    templatesBlock;

    /**
     * Object that contains all the needed information for any template used
     * @type {{calendarViewCourseSlot: {dataTemplate: string, placeholders: {image: string, timeStart: string, title: string}}, calendarViewEventSlot: {dataTemplate: string, placeholders: {timeStart: string, description: string, title: string}}}}
     */
    templatesMap = {
        calendarViewEventSlot: {
            dataTemplate: 'calendar-view-event-slot',
            placeholders: {
                imageContainer: {
                    type: 'attribute',
                    attribute: 'style',
                    selector: '.js-image',
                },
                image: {
                    type: 'innerHTML',
                    selector: '.js-image',
                },
                timeStart: {
                    type: 'innerHTML',
                    selector: '.js-time-start',
                },
                status: {
                    type: 'innerHTML',
                    selector: '.js-status',
                },
                title: {
                    type: 'innerHTML',
                    selector: '.js-title',
                },
            },
        },
        calendarViewCourseSlot: {
            dataTemplate: 'calendar-view-course-slot',
            placeholders: {
                timeStart: {
                    type: 'innerHTML',
                    selector: '.js-time-start',
                },
                title: {
                    type: 'innerHTML',
                    selector: '.js-title',
                },
                status: {
                    type: 'innerHTML',
                    selector: '.js-status',
                },
                description: {
                    type: 'innerHTML',
                    selector: '.js-description',
                },
            },
        },
        listViewBlock: {
            dataTemplate: 'list-view-list',
            placeholders: {
                title: {
                    type: 'innerHTML',
                    selector: '.js-list-title',
                },
                items: {
                    type: 'innerHTML',
                    selector: '.js-list-items',
                },
            },
        },
        listViewEventSlot: {
            dataTemplate: 'list-view-event-slot',
            placeholders: {
                date: {
                    type: 'innerHTML',
                    selector: '.js-date',
                },
                time: {
                    type: 'innerHTML',
                    selector: '.js-time',
                },
                title: {
                    type: 'innerHTML',
                    selector: '.js-title',
                },
                image: {
                    type: 'innerHTML',
                    selector: '.js-image',
                },
                locationsContainer: {
                    type: 'attribute',
                    attribute: 'style',
                    selector: '.js-locations-container',
                },
                locations: {
                    type: 'innerHTML',
                    selector: '.js-locations',
                },
                locationsTextContainer: {
                    type: 'classList',
                    selector: '.js-locations-text-container',
                    clear: false,
                },
                locationTypeIcon: {
                    type: 'classList',
                    selector: '.js-locations-circle',
                    clear: true,
                },
                costContainer: {
                    type: 'attribute',
                    attribute: 'style',
                    selector: '.js-cost-container',
                },
                cost: {
                    type: 'innerHTML',
                    selector: '.js-cost',
                },
                link: {
                    type: 'attribute',
                    attribute: 'href',
                    selector: '.js-link',
                },
            },
        },
        listViewCourseSlot: {
            dataTemplate: 'list-view-course-slot',
            placeholders: {
                date: {
                    type: 'innerHTML',
                    selector: '.js-date',
                },
                time: {
                    type: 'innerHTML',
                    selector: '.js-time',
                },
                title: {
                    type: 'innerHTML',
                    selector: '.js-title',
                },
                locationsContainer: {
                    type: 'attribute',
                    attribute: 'style',
                    selector: '.js-locations-container',
                },
                locations: {
                    type: 'innerHTML',
                    selector: '.js-locations',
                },
                locationTypeIcon: {
                    type: 'classList',
                    selector: '.js-locations-circle',
                    clear: true,
                },
                locationsTextContainer: {
                    type: 'classList',
                    selector: '.js-locations-text-container',
                    clear: false,
                },
                link: {
                    type: 'attribute',
                    attribute: 'href',
                    selector: '.js-link',
                },
            },
        },
        slotTooltip: {
            dataTemplate: 'slot-tooltip',
            placeholders: {
                title: {
                    type: 'innerHTML',
                    selector: '.js-title',
                },
                date: {
                    type: 'innerHTML',
                    selector: '.js-date',
                },
                time: {
                    type: 'innerHTML',
                    selector: '.js-time',
                },
            },
        },
    };

    /**
     * URL for fetching slots
     * @type {string}
     */
    apiEndPoint;

    /**
     * Mode for window width, which can take one of two values: 'mobile' or 'desktop'.
     * @type {string}
     */
    windowMode = 'desktop';

    /**
     * Creates an instance of the CalendarA1 class
     * @param {HTMLElement} container - The HTML element of the block container
     */
    constructor(container) {
        this.container = container;
        this.observer = new Observer();
        this.apiEndPoint = this.getApiBaseURL() + CalendarA1.SLOTS_ENDPOINT;
        this.windowMode =
            window.innerWidth > CalendarA1.MAX_MOBILE_RESOLUTION
                ? 'desktop'
                : 'mobile';

        // Setting an initial state for the block from the URL query params
        this.updateStateFromURL();
        // Determine all the needed elements
        this.initElements();
        // Setting initial state for all the block features
        this.setInitialState();
        // Add Event Listeners
        this.initListeners();
        // Add subscriptions
        this.initSubscriptions();
        // Initialize the Full Calendar class instance
        this.initCalendar();
        // Initialize the Mixer class instance
        this.initMixer();
    }

    /**
     * Sets some elements as class properties
     */
    initElements() {
        this.grid = this.container.querySelector(`[data-role='calendar-grid']`);
        this.loader = this.container.querySelector(
            `[data-role='calendar-loader']`,
        );
        this.paginationPrev = this.container.querySelector(
            `.js-calendar-pagination-prev`,
        );
        this.paginationNext = this.container.querySelector(
            `.js-calendar-pagination-next`,
        );
        this.filterButton = this.container.querySelector(
            `[data-role='filters-btn']`,
        );
        this.filtersBlock = this.container.querySelector(
            `[data-role='filters-block']`,
        );
        this.showMoreButtons = this.container.querySelectorAll(
            `[data-role='show-more-btn']`,
        );
        this.applyFiltersButton = this.container.querySelector(
            `[data-role='filtering-submit-btn']`,
        );
        this.clearFiltersButton = this.container.querySelector(
            `[data-role='filtering-clear-btn']`,
        );
        this.viewButton = this.container.querySelector(
            `[data-role='list-view-btn']`,
        );
        this.crumbsContainer = this.container.querySelector(
            `[data-role='crumbs-container']`,
        );
        this.filterInputs = this.container.querySelectorAll(
            `[data-role='filtering-input']`,
        );
        this.filterCrumbs = this.container.querySelectorAll(
            `[data-role='filters-crumb']`,
        );
        this.templatesBlock = this.container.querySelector(
            `[data-role='templates']`,
        );
    }

    /**
     * Add needed event listeners
     */
    initListeners() {
        if (this.filterButton) {
            this.filterButton.addEventListener(
                'click',
                this.filtersBlockButtonHandler.bind(this),
            );
        }
        if (this.showMoreButtons.length) {
            [...this.showMoreButtons].forEach((button) => {
                button.addEventListener(
                    'click',
                    this.showMoreButtonsHandler.bind(this),
                );
            });
        }
        if (this.applyFiltersButton) {
            this.applyFiltersButton.addEventListener(
                'click',
                this.applyFiltersButtonHandler.bind(this),
            );
        }
        if (this.clearFiltersButton) {
            this.clearFiltersButton.addEventListener(
                'click',
                this.clearFiltersButtonHandler.bind(this),
            );
        }
        if (this.viewButton) {
            this.viewButton.addEventListener(
                'click',
                this.viewButtonHandler.bind(this),
            );
        }
        if (this.filterInputs.length) {
            [...this.filterInputs].forEach((input) => {
                input.addEventListener(
                    'change',
                    this.filteringInputChangeHandler.bind(this),
                );
            });
        }
        if (this.filterCrumbs.length) {
            [...this.filterCrumbs].forEach((crumb) => {
                crumb.addEventListener(
                    'click',
                    this.filtersCrumbClickHandler.bind(this),
                );
            });
        }
        // window resize event listener
        window.addEventListener('resize', this.resizeEventHandler.bind(this));
        // 'becameMobileMode' resize event
        this.container.addEventListener(
            'becameMobileMode',
            this.becameMobileModeHandler.bind(this),
        );
        // 'becameDesktopMode' resize event
        this.container.addEventListener(
            'becameDesktopMode',
            this.becameDesktopModeHandler.bind(this),
        );
    }

    /**
     * In this method all the observer subscriptions are listed
     */
    initSubscriptions() {
        this.observer.subscribe('calendarInitialized', (data) => {
            this.initCalendarPagination();

            // Getting Dates Range for the current view
            const datesRange = this.getCalendarDatesRange();
            // Updating the current State object
            this.updateState(
                {
                    date_from: datesRange.startDate
                        ? this.getFormattedDateString(datesRange.startDate)
                        : '',
                    date_to: datesRange.endDate
                        ? this.getFormattedDateString(datesRange.endDate)
                        : '',
                    view: data.view,
                },
                false,
            );

            // Fetching slots
            this.fetchSlots();
        });
        this.observer.subscribe('fetchSlotsCompleted', (data) => {
            this.refreshCalendarEvents(data.slots);
        });
        this.observer.subscribe('calendarPagination', (data) => {
            const currentDate = this.calendar.getDate();
            const currentView = this.calendar.view;

            if (data.prev) {
                if (currentView.type === 'custom') {
                    // Move back by 7 days
                    const newDate = new Date(currentDate);
                    newDate.setDate(newDate.getDate() - 7);
                    this.calendar.gotoDate(newDate);
                } else if (currentView.type === 'dayGridMonth') {
                    this.calendar.prev();
                }
            } else if (data.next) {
                if (currentView.type === 'custom') {
                    // Move forward by 7 days
                    const newDate = new Date(currentDate);
                    newDate.setDate(newDate.getDate() + 7);
                    this.calendar.gotoDate(newDate);
                } else if (currentView.type === 'dayGridMonth') {
                    this.calendar.next();
                }
            }

            // Getting Dates Range for the current view
            const datesRange = this.getCalendarDatesRange();

            // Updating the current State object
            this.updateState({
                date_from: this.getFormattedDateString(datesRange.startDate),
                date_to: this.getFormattedDateString(datesRange.endDate),
            });

            // Fetching slots
            this.fetchSlots();
        });
    }

    /**
     * Initializes pagination buttons for the Calendar
     */
    initCalendarPagination() {
        if (this.paginationPrev) {
            this.paginationPrev.addEventListener('click', (event) => {
                event.preventDefault();

                if (!this.calendar) {
                    return;
                }

                this.hideFiltersBlock();
                this.observer.notify('calendarPagination', {
                    prev: true,
                    next: false,
                });
            });
        }
        if (this.paginationNext) {
            this.paginationNext.addEventListener('click', (event) => {
                event.preventDefault();
                if (!this.calendar) {
                    return;
                }

                this.hideFiltersBlock();
                this.observer.notify('calendarPagination', {
                    prev: false,
                    next: true,
                });
            });
        }
    }

    /**
     * Renders the FullCalendar
     * @param {Object[]} slots
     */
    refreshCalendarEvents(slots) {
        this.calendar.removeAllEvents();
        if (slots.length > 0) {
            let preparedSlots = [];
            slots.forEach((slot) => {
                preparedSlots.push(this.prepareSlot(slot));
            });
            this.calendar.addEventSource(preparedSlots);
        }
    }

    /**
     * Returns a new custom plugin for FullCalendar.
     * This plugin allows to create a custom calendar view
     * @return {PluginDef}
     */
    customViewPlugin() {
        const CustomViewConfig = {
            classNames: ['custom-view'],
            duration: {
                weeks: 1,
            },
            content: (props) => {
                let eventsSlices = sliceEvents(props, true);
                const { events, courses } = this.sortEvents(eventsSlices);

                let html = '';
                let coursesHtml = '';
                let eventsHtml = '';

                // Block that contains events slots
                if (events.length > 0) {
                    events.forEach((event) => {
                        // Current slot Instance
                        const slot = event.def;
                        // Slot Start Date
                        const slotStartDate = event.range.start;
                        // Date Month Part
                        const slotStartDateMonth = (
                            slotStartDate.getUTCMonth() + 1
                        )
                            .toString()
                            .padStart(2, '0');
                        // Date Day Part
                        const slotStartDateDay = slotStartDate
                            .getUTCDate()
                            .toString()
                            .padStart(2, '0');
                        // Slot Start Date in a needed format
                        const slotStartDateString = `${slotStartDateMonth}.${slotStartDateDay}`;
                        // Slot start time in a needed format
                        const slotStartTime = slot.extendedProps.time_start;
                        const slotStartTimeString =
                            this.getFormattedTime(slotStartTime);
                        // Slot week day
                        const slotStartWeekDay = slotStartDate.toLocaleString(
                            'en-US',
                            {
                                weekday: 'short',
                                timeZone: 'UTC',
                            },
                        );
                        // Slot Week Day + its time
                        const slotWeekDayAndTime = `${slotStartWeekDay} | ${slotStartTimeString}`;
                        // Primary location
                        let primaryLocation =
                            event.def.extendedProps.primary_location;
                        // Primary Location Term name
                        let locationString = primaryLocation
                            ? primaryLocation.name
                            : '';
                        // Locations HTML container style attribute value
                        let locationContainerStyle = locationString
                            ? ''
                            : 'display:none;';
                        // Location Type Icon
                        let locationTypeIconClasses = [
                            'a-meta__circle',
                            'w-3',
                            'h-3',
                            'mt-[0.35em]',
                            'shrink-0',
                            'rounded-full',
                            'text-inherit',
                            'js-locations-circle',
                        ];
                        if (
                            primaryLocation &&
                            primaryLocation.meta &&
                            primaryLocation.meta.type &&
                            primaryLocation.meta.type === 'virtual'
                        ) {
                            locationTypeIconClasses =
                                locationTypeIconClasses.concat([
                                    'bg-transparent',
                                    'border',
                                    'border-solid',
                                    'border-current',
                                ]);
                        } else {
                            locationTypeIconClasses =
                                locationTypeIconClasses.concat(['bg-current']);
                        }

                        // Slot Image
                        const slotImage = slot.extendedProps.image_url
                            ? `<img class="w-full h-full object-cover object-center" src="${slot.extendedProps.image_url}" alt="${slot.title}">`
                            : '';
                        // Cost
                        let cost = slot.extendedProps.cost;
                        if (cost) {
                            cost =
                                cost.toLowerCase() === 'free'
                                    ? cost
                                    : `$${cost}`;
                        }

                        eventsHtml += this.getTemplateView(
                            'listViewEventSlot',
                            {
                                date: slotStartDateString,
                                time: slotWeekDayAndTime,
                                title:
                                    slot.extendedProps.status.toLowerCase() ===
                                    'cancelled'
                                        ? `<span class="title-cancelled text-brand-color-10 uppercase">${slot.extendedProps.status}</span> ${slot.title}`
                                        : slot.title,
                                image: slotImage,
                                locationsContainer: locationContainerStyle,
                                locations: locationString,
                                locationsTextContainer: '!text-brand-color-4',
                                locationTypeIcon:
                                    locationTypeIconClasses.join(' '),
                                costContainer: slot.extendedProps.cost
                                    ? ''
                                    : 'display:none;',
                                cost: cost,
                                link: slot.extendedProps.post_permalink,
                            },
                        );
                    });
                    eventsHtml = this.getTemplateView('listViewBlock', {
                        title: 'Events',
                        items: eventsHtml,
                    });
                }
                // Block that contains courses slots
                if (courses.length > 0) {
                    courses.forEach((event) => {
                        // Current slot Instance
                        const slot = event.def;
                        // Slot Start Date
                        const slotStartDate = event.range.start;
                        // Date Month Part
                        const slotStartDateMonth = (
                            slotStartDate.getUTCMonth() + 1
                        )
                            .toString()
                            .padStart(2, '0');
                        // Date Day Part
                        const slotStartDateDay = slotStartDate
                            .getUTCDate()
                            .toString()
                            .padStart(2, '0');
                        // Slot Start Date in a needed format
                        const slotStartDateString = `${slotStartDateMonth}.${slotStartDateDay}`;
                        // Slot start time in a needed format
                        const slotStartTime = slot.extendedProps.time_start;
                        const slotStartTimeString =
                            this.getFormattedTime(slotStartTime);
                        // Slot week day
                        const slotStartWeekDay = slotStartDate.toLocaleString(
                            'en-US',
                            {
                                weekday: 'short',
                                timeZone: 'UTC',
                            },
                        );
                        // Slot Week Day + its time
                        const slotWeekDayAndTime = `${slotStartWeekDay} | ${slotStartTimeString}`;
                        // Primary location
                        let primaryLocation =
                            event.def.extendedProps.primary_location;
                        // Primary Location Term name
                        let locationString = primaryLocation
                            ? primaryLocation.name
                            : '';
                        // Locations HTML container style attribute value
                        let locationContainerStyle = locationString
                            ? ''
                            : 'display:none;';

                        // Location Type Icon
                        let locationTypeIconClasses = [
                            'a-meta__circle',
                            'w-3',
                            'h-3',
                            'mt-[0.35em]',
                            'shrink-0',
                            'rounded-full',
                            'text-inherit',
                            'js-locations-circle',
                        ];
                        if (
                            primaryLocation &&
                            primaryLocation.meta &&
                            primaryLocation.meta.type &&
                            primaryLocation.meta.type === 'virtual'
                        ) {
                            locationTypeIconClasses =
                                locationTypeIconClasses.concat([
                                    'bg-transparent',
                                    'border',
                                    'border-solid',
                                    'border-current',
                                ]);
                        } else {
                            locationTypeIconClasses =
                                locationTypeIconClasses.concat(['bg-current']);
                        }

                        coursesHtml += this.getTemplateView(
                            'listViewCourseSlot',
                            {
                                date: slotStartDateString,
                                time: slotWeekDayAndTime,
                                title:
                                    slot.extendedProps.status.toLowerCase() ===
                                    'cancelled'
                                        ? `<span class="title-cancelled text-brand-color-10 uppercase">${slot.extendedProps.status}</span> ${slot.title}`
                                        : slot.title,
                                locationsContainer: locationContainerStyle,
                                locations: locationString,
                                locationsTextContainer: '!text-brand-color-1',
                                locationTypeIcon:
                                    locationTypeIconClasses.join(' '),
                                link: slot.extendedProps.post_permalink,
                                status: '',
                            },
                        );
                    });
                    coursesHtml = this.getTemplateView('listViewBlock', {
                        title: 'Courses',
                        items: coursesHtml,
                    });
                }

                // Combining two block into a single container
                html = `
                    <div class="${CalendarA1.COMPONENT_CLASS}__custom-view">
                        ${eventsHtml}
                        ${coursesHtml}
                    </div>`;

                return { html };
            },
        };

        return createPlugin({
            views: {
                custom: CustomViewConfig,
            },
        });
    }

    /**
     * It takes an array of all the slots available as an arguments
     * Then the full list of slots is being split into two groups: events and courses/classes
     * Slots list within both groups are being sorted
     * @param {Array} slots
     * @return {Object}
     */
    sortEvents(slots) {
        const [events, courses] = slots.reduce(
            (result, item) => {
                if (item.def.extendedProps.type === 'event') {
                    result[0].push(item); // Add the item to the first sub-array
                } else if (item.def.extendedProps.type === 'course') {
                    result[1].push(item); // Add the item to the second sub-array
                }
                return result;
            },
            [[], []],
        );

        events.sort(this.compareEvents);
        courses.sort(this.compareEvents);

        return {
            events,
            courses,
        };
    }

    /**
     * This function takes to events as arguments and return them in a needed order.
     * Used as a events sorting function callback
     * @param a
     * @param b
     * @return {number|number}
     */
    compareEvents(a, b) {
        if (!a.def.extendedProps.time_start) {
            return -1;
        }
        if (!b.def.extendedProps.time_start) {
            return 1;
        }
        // Date A
        const aDate = new Date(a.range.start);
        const [aHours, aMinutes, aSeconds] =
            a.def.extendedProps.time_start.split(':');
        aDate.setHours(aHours);
        aDate.setMinutes(aMinutes);
        aDate.setSeconds(aSeconds);

        // Date B
        const bDate = new Date(b.range.start);
        const [bHours, bMinutes, bSeconds] =
            b.def.extendedProps.time_start.split(':');
        bDate.setHours(bHours);
        bDate.setMinutes(bMinutes);
        bDate.setSeconds(bSeconds);

        if (aDate === bDate) {
            return 0;
        }

        return aDate > bDate ? 1 : -1;
    }

    /**
     * Initializes the FullCalendar for this block with some default parameters and without any events yet
     */
    initCalendar() {
        const customViewPlugin = this.customViewPlugin();

        // Initial date for the Calendar
        let initialDate = new Date();
        if (this.state.date_from.value) {
            initialDate =
                this.getDateObject(this.state.date_from.value) ?? initialDate;
        }

        let initialStateViewValue =
            this.state.view.value === 'list' || this.windowMode === 'mobile'
                ? 'list'
                : 'calendar';
        // Calendar View on init
        let initialView =
            initialStateViewValue === 'list' ? 'custom' : 'dayGridMonth';

        this.calendar = new Calendar(this.grid, {
            plugins: [
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
                customViewPlugin,
            ],
            initialView: initialView,
            initialDate: initialDate,
            headerToolbar: null,
            eventOrder: (a, b) => {
                // Show events first, the show courses
                if (
                    a.extendedProps.type === 'event' &&
                    b.extendedProps.type === 'course'
                ) {
                    return -1;
                } else if (
                    a.extendedProps.type === 'course' &&
                    b.extendedProps.type === 'event'
                ) {
                    return 1;
                }

                if (!a.extendedProps.time_start || !a.start) {
                    return -1;
                }
                if (!b.extendedProps.time_start || !b.start) {
                    return 1;
                }

                // Date A
                const aDate = new Date(a.start);
                const [aHours, aMinutes, aSeconds] =
                    a.extendedProps.time_start.split(':');
                aDate.setHours(aHours);
                aDate.setMinutes(aMinutes);
                aDate.setSeconds(aSeconds);

                // Date B
                const bDate = new Date(b.start);
                const [bHours, bMinutes, bSeconds] =
                    b.extendedProps.time_start.split(':');
                bDate.setHours(bHours);
                bDate.setMinutes(bMinutes);
                bDate.setSeconds(bSeconds);

                if (aDate === bDate) {
                    return 0;
                }

                return aDate > bDate ? 1 : -1;
            },
            height: 'auto',
            fixedWeekCount: false,
            eventContent: (info) => {
                return {
                    html: this.getEventSlotView(info.event),
                };
            },
            eventClassNames: (arg) => {
                if (arg.event.extendedProps.type) {
                    return [
                        `${CalendarA1.COMPONENT_CLASS}__event-slot-link`,
                        `${CalendarA1.COMPONENT_CLASS}__event-slot-link--${arg.event.extendedProps.type}`,
                    ];
                }
            },
            eventDidMount: (args) => {
                // Initialize tooltip for each event element
                if (CalendarA1.EVENT_CLICK_MODE === 'tooltip') {
                    let tooltip = tippy(args.el, {
                        content: this.getTooltipView(args.event),
                        placement: 'left',
                        animation: 'scale',
                        allowHTML: true,
                        trigger: 'click',
                        interactive: true,
                        arrow: roundArrow,
                        theme: 'light',
                    });
                }
            },
            viewDidMount: (view) => {
                //
            },
            datesSet: (args) => {
                this.updateHeader();
            },
        });
        this.calendar.render();
        this.observer.notify('calendarInitialized', {
            calendar: this.calendar,
            view: initialStateViewValue,
        });
    }

    /**
     * Initializes a new Mixer Instance for filters items
     */
    initMixer() {
        if (!this.crumbsContainer) {
            return;
        }

        // Building selector for initial filtering
        let selectorsFromUrl = this.filtersItemsMap
            .filter((item) => {
                return item.includedInMixer;
            })
            .map((item) => {
                return this.state[item.queryParam] &&
                    this.state[item.queryParam].value
                    ? this.state[item.queryParam].value.split(',')
                    : [];
            })
            .flat()
            .map((value) => {
                return `[data-role='filters-crumb'][data-term-id='${value}']`;
            })
            .join(',');

        this.mixer = mixitup(this.crumbsContainer, {
            load: {
                filter: 'none',
            },
            callbacks: {
                onMixStart: (state, futureState) => {
                    this.crumbsContainer.classList.remove('opacity-0');
                },
            },
        });

        // If we have a non-empty selector - run the first mix
        if (selectorsFromUrl) {
            this.mixer.filter(selectorsFromUrl);
        }
    }

    /**
     * Returns an HTML markup for the passed event
     * @param {Object} event
     * @return {string}
     */
    getTooltipView(event) {
        return this.getTemplateView('slotTooltip', {
            title: event.title,
            date: this.getFormattedDateString(event.start),
            time: this.getFormattedTime(event.extendedProps.time_start),
        });
    }

    /**
     * Returns HTML markup for slot for the calendar view
     * @param event
     * @return {string}
     */
    getEventSlotView(event) {
        let template = '';
        const image = event.extendedProps.image_url
            ? `<img class="block w-full h-full object-cover object-center" alt="${event.title}" src="${event.extendedProps.image_url}">`
            : '';
        const eventViewData = {
            imageContainer: image ? '' : 'display:none;',
            image: image,
            timeStart: this.getFormattedTime(event.extendedProps.time_start),
            status: '',
            title: event.title,
            description: '',
        };

        if (event.extendedProps.status.toLowerCase() === 'cancelled') {
            eventViewData.status = `<span class="title-cancelled text-brand-color-10 uppercase font-semibold mb-1">${event.extendedProps.status}</span>`;
        }

        if (event.extendedProps.type === 'event') {
            template = 'calendarViewEventSlot';
        } else if (event.extendedProps.type === 'course') {
            template = 'calendarViewCourseSlot';
        }

        return this.getTemplateView(template, eventViewData);
    }

    /**
     * Returns a string with properly formatted time
     * @param {string} timeString
     * @return {string}
     */
    getFormattedTime(timeString) {
        if (!timeString) {
            return '';
        }
        let timeParts = timeString.split(':');
        let hours = parseInt(timeParts[0], 10);
        let minutes = parseInt(timeParts[1], 10);

        let date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);

        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    }

    /**
     * Returns a Dat string in a readable format
     * @param {Date} date
     * @return {string}
     */
    getFormattedDate(date) {
        const options = {
            month: 'long',
            day: 'numeric',
            weekday: 'long',
        };

        return date.toLocaleString('en-US', options);
    }

    /**
     * Converts an Event Slot object from backend response to an object with needed parameters, which can be used as FullCalendar Event
     * @param {Object} slot - An Event Slot object from the backend response
     * @return {Object}     - Event object for using as calendar event
     */
    prepareSlot(slot) {
        return {
            id: slot.id,
            groupId: slot.id,
            allDay: true,
            title: slot.name,
            start: slot.date,
            url:
                CalendarA1.EVENT_CLICK_MODE === 'link'
                    ? slot.post_permalink
                    : '',
            editable: false,
            startEditable: false,
            durationEditable: false,
            resourceEditable: false,
            display: 'block',
            overlap: false,
            backgroundColor: 'rgba(255, 255, 255, 0)',
            borderColor: 'rgba(255, 255, 255, 0)',
            textColor: '#000000',
            extendedProps: {
                cost: slot.cost,
                details: slot.details,
                image: slot.image_id,
                link: slot.link,
                primary_location: slot.primary_location,
                categories: slot.categories,
                post_id: slot.post_id,
                repeater_index: slot.repeater_index,
                status: slot.status,
                time_start: slot.time_start,
                time_end: slot.time_end,
                type: slot.type,
                image_url: slot.image_url ?? '',
                post_permalink: slot.post_permalink ?? '',
            },
        };
    }

    /**
     * Fetching Event Slots from backend side by axios request
     */
    fetchSlots() {
        this.showLoader();

        // Building object of query params from the state object
        const queryParams = Object.fromEntries(
            Object.entries(this.state)
                .filter(
                    ([key, value]) => value.includeToFetch && value.fetchKey,
                )
                .map(([key, value]) => [value.fetchKey, value.value]),
        );

        axios
            .get(this.apiEndPoint, {
                params: queryParams,
            })
            .then((response) => {
                // Handle the successful response
                this.observer.notify('fetchSlotsCompleted', {
                    slots: response.data.data,
                });
            })
            .catch((error) => {
                // Handle the failed response
                this.observer.notify('fetchSlotsFailed', {
                    error: error,
                });
            })
            .finally(() => {
                this.hideLoader();
            });
    }

    /**
     * Shows a loader above the whole calendar container
     */
    showLoader() {
        if (!this.loader) {
            return;
        }

        this.loader.dataset.visible = 'true';
    }

    /**
     * Hides a loader
     */
    hideLoader() {
        if (!this.loader) {
            return;
        }

        this.loader.dataset.visible = 'false';
    }

    /**
     * Returns starting day and the last day for the Calendar current view
     * @return {{endDate: ?Date, startDate: ?Date}}
     */
    getCalendarDatesRange() {
        let startDate = null;
        let endDate = null;

        if (
            this.calendar &&
            this.calendar.view.currentStart &&
            this.calendar.view.currentEnd
        ) {
            startDate = this.calendar.view.currentStart;
            endDate = this.calendar.view.currentEnd;
            endDate.setDate(endDate.getDate() - 1);
        }

        return {
            startDate,
            endDate,
        };
    }

    /**
     * Updates the global state object for existing keys
     * @param {Object} params
     * @param {Boolean} updateUrl
     */
    updateState(params, updateUrl = true) {
        for (const key in params) {
            if (this.state[key] !== undefined) {
                this.state[key].value = params[key];
            }
        }

        if (updateUrl) {
            this.updateURLFromState();
        }
    }

    /**
     * Returns a string representation of the passed Date() object instance in a needed format
     * @param {Date} date
     * @return {string}
     */
    getFormattedDateString(date) {
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');

        return year + '-' + month + '-' + day;
    }

    /**
     * Updates the Calendar block header
     */
    updateHeader() {
        const titleYear = this.container.querySelector(
            `[data-role='calendar-title-year']`,
        );
        const titleDates = this.container.querySelector(
            `[data-role='calendar-title-dates']`,
        );
        const dates = this.getCalendarDatesRange();

        if (!dates.startDate || !dates.endDate) {
            return;
        }

        if (titleYear) {
            const startDateYear = dates.startDate.getFullYear();
            const endDateYear = dates.endDate.getFullYear();
            titleYear.innerHTML =
                startDateYear === endDateYear
                    ? startDateYear
                    : `${startDateYear} - ${endDateYear}`;
        }
        if (titleDates) {
            const startDateMonth = dates.startDate.toLocaleString('en-US', {
                month: 'short',
            });
            const endDateMonth = dates.endDate.toLocaleString('en-US', {
                month: 'short',
            });
            const startDateDay = dates.startDate.getDate();
            const endDateDay = dates.endDate.getDate();
            titleDates.innerHTML = `${startDateMonth} ${startDateDay} - ${endDateMonth} ${endDateDay}`;
        }
    }

    /**
     * Show/Hide filters block
     * @param event
     */
    filtersBlockButtonHandler(event) {
        event.preventDefault();

        if (!this.filtersBlock) {
            return;
        }

        const isVisible = this.filtersBlock.dataset.visible === 'true';
        if (isVisible) {
            this.hideFiltersBlock();
        } else {
            this.showFiltersBlock();
        }
    }

    /**
     * Opens the filters block
     */
    showFiltersBlock() {
        jQuery(this.filtersBlock).slideDown(400, () => {
            this.filtersBlock.dataset.visible = 'true';
        });
    }

    /**
     * Hides the filters block
     */
    hideFiltersBlock() {
        jQuery(this.filtersBlock).slideUp(400, () => {
            this.filtersBlock.dataset.visible = 'false';
        });
    }

    /**
     * Shows/Hides some lists in the filters block
     * @param event
     */
    showMoreButtonsHandler(event) {
        event.preventDefault();

        const target = event.target;
        const button = target.closest(`[data-role='show-more-btn']`);
        const listsContainer = target.closest(`[data-role='filtering-column']`);
        if (!listsContainer) {
            return;
        }
        const hiddenList = listsContainer.querySelector(
            `[data-role='filtering-hidden-list']`,
        );
        if (!hiddenList) {
            return;
        }
        const isVisible = hiddenList.dataset.visible === 'true';
        const buttonLabel = button.querySelector(
            `[data-role='show-more-btn-label']`,
        );
        const buttonIcon = button.querySelector(
            `[data-role='show-more-btn-icon']`,
        );
        if (isVisible) {
            jQuery(hiddenList).slideUp(400, () => {
                buttonLabel.innerText = 'Show More';
                hiddenList.dataset.visible = (!isVisible).toString();
            });
            buttonIcon.classList.add('rotate-180');
        } else {
            jQuery(hiddenList).slideDown({
                duration: 400,
                start: function () {
                    jQuery(this).css({
                        display: 'flex',
                    });
                },
                done: () => {
                    buttonLabel.innerText = 'Show Less';
                    hiddenList.dataset.visible = (!isVisible).toString();
                },
            });
            buttonIcon.classList.remove('rotate-180');
        }
    }

    /**
     * Fires when user clicks the Search button
     * @param event
     */
    applyFiltersButtonHandler(event) {
        event.preventDefault();

        this.updateStateByFilters();
        this.hideFiltersBlock();
        this.fetchSlots();
    }

    /**
     * Fires when the 'Clear' button is clicked
     * @param event
     */
    clearFiltersButtonHandler(event = null) {
        event.preventDefault();

        this.filtersItemsMap.forEach((filterItem) => {
            if (filterItem.tag === 'input') {
                if (filterItem.type === 'checkbox') {
                    // Processing input[type='checkbox']
                    let checkedInputs = this.container.querySelectorAll(
                        `input[type='checkbox'][name='${filterItem.name}']:checked`,
                    );
                    if (!checkedInputs.length) {
                        return;
                    }
                    checkedInputs.forEach((input) => {
                        input.checked = false;
                    });
                } else if (filterItem.type === 'text') {
                    // Processing input[type='text']
                    let input = this.container.querySelector(
                        `input[type='text'][name=${filterItem.name}]`,
                    );
                    if (!input) {
                        return;
                    }
                    input.value = '';
                }
            }
        });

        this.filteringInputChangeHandler();
    }

    /**
     * Processing all the filters inputs specified in the filtersItemsMap array and use these filters values for updating the state class parameter
     */
    updateStateByFilters() {
        const filtersData = {};
        this.filtersItemsMap.forEach((filterItem) => {
            if (filterItem.tag === 'input' && filterItem.type === 'checkbox') {
                // Processing input[type='checkbox']
                let allInputs = this.container.querySelectorAll(
                    `input[type='checkbox'][name=${filterItem.name}]`,
                );
                if (!allInputs.length) {
                    return;
                }
                let checkedInputs = [];
                allInputs.forEach((input) => {
                    if (input.checked) {
                        checkedInputs.push(input.value);
                    }
                });
                filtersData[filterItem.queryParam] = checkedInputs.join(',');
            } else if (
                filterItem.tag === 'input' &&
                filterItem.type === 'text'
            ) {
                // Processing input[type='text']
                let input = this.container.querySelector(
                    `input[type='text'][name=${filterItem.name}]`,
                );
                if (!input) {
                    return;
                }
                filtersData[filterItem.queryParam] = input.value;
            }
        });

        this.updateState(filtersData);
    }

    /**
     * Returns current site base URL
     * @return {*}
     */
    getApiBaseURL() {
        return window.location.origin;
    }

    /**
     * Fires when the 'List/Month View' button is clicked
     * @param {Event} event
     */
    viewButtonHandler(event) {
        event.preventDefault();

        if (!this.calendar) {
            return;
        }
        const button = event.target.closest(`[data-role='list-view-btn']`);
        if (!button) {
            return;
        }

        const currentView = this.calendar.view.type;
        let newView = null;
        let buttonMode = '';
        let newViewLabel = '';
        let newDatesRange = {};
        if (currentView === 'dayGridMonth') {
            newView = 'custom';
            buttonMode = 'dayGridMonth';
            newViewLabel = 'list';
            newDatesRange = this.getWeekRange(this.calendar.getDate());
        } else if (currentView === 'custom') {
            newView = 'dayGridMonth';
            buttonMode = 'custom';
            newViewLabel = 'calendar';
            newDatesRange = this.getMonthRange(this.calendar.getDate());
        }
        if (newView) {
            // Check whether a new slots fetch is needed by comparing current dates range and new ones
            const isNewFetchNeeded = this.checkSlotsDatesRange(newDatesRange);

            // Switching to a new view and modifying its dates range
            this.calendar.changeView(newView, newDatesRange);
            this.updateState({
                view: newViewLabel,
                date_from: this.getFormattedDateString(newDatesRange.start),
                date_to: this.getFormattedDateString(newDatesRange.end),
            });

            this.updateViewsButton(buttonMode);

            // Fetch new slots if needed
            if (isNewFetchNeeded) {
                this.fetchSlots();
            }
        }
    }

    /**
     *
     * @param {{start: Date, end: Date}} newDatesRange
     * @return {boolean}
     */
    checkSlotsDatesRange(newDatesRange) {
        const currentDateStart = new Date(this.state.date_from.value);
        const currentDateEnd = new Date(this.state.date_to.value);

        return (
            newDatesRange.start < currentDateStart ||
            newDatesRange.end > currentDateEnd
        );
    }

    /**
     * Returns an object with start and end keys representing the start and end days of the week for the given input day.
     * @param {Date} date
     * @return {Object} datesRange
     */
    getWeekRange(date) {
        const weekDay = date.getDay();

        const start = new Date(date);
        const end = new Date(date);

        start.setDate(date.getDate() - weekDay);
        start.setHours(0, 0, 0, 0);
        end.setDate(date.getDate() + (7 - weekDay));
        end.setHours(0, 0, 0, 0);

        return {
            start,
            end,
        };
    }

    /**
     * Returns a month dates range
     * @param {Date} date
     * @return {{start: Date, end: Date}}
     */
    getMonthRange(date) {
        const start = new Date(date);
        start.setDate(1);
        start.setHours(0, 0, 0);

        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        end.setHours(0, 0, 0);

        return {
            start,
            end,
        };
    }

    /**
     * Fires on filters input change Event OR it can be executed manually.
     * It loops over all the filter items and show/hide their crumbs with Mixer
     */
    filteringInputChangeHandler() {
        if (this.mixer) {
            let checked = Array.from(this.filterInputs)
                .filter((checkbox) => {
                    return checkbox.checked;
                })
                .map((checkbox) => {
                    return `[data-role='filters-crumb'][data-term-id='${checkbox.value}']`;
                });
            this.mixer.filter(checked.length > 0 ? checked.join(',') : 'none');
        }
    }

    /**
     * Fires when a user clicks a filters crumb
     * @param {Event} event
     */
    filtersCrumbClickHandler(event) {
        event.preventDefault();

        const target = event.target;
        const crumb = target.closest(`[data-role='filters-crumb']`);
        if (!crumb) {
            return;
        }
        const value = crumb.dataset.termId ? parseInt(crumb.dataset.termId) : 0;
        if (!value) {
            return;
        }

        [...this.filterInputs].forEach((input) => {
            if (Number(input.value) === value) {
                input.checked = false;
                input.dispatchEvent(
                    new Event('change', {
                        bubbles: true,
                    }),
                );
            }
        });

        this.updateStateByFilters();
        this.hideFiltersBlock();
        this.fetchSlots();
    }

    /**
     * Modifies the state parameter of the class, setting values from the URL query params
     */
    updateStateFromURL() {
        const urlParams = new URLSearchParams(window.location.search);

        const newState = {};
        for (const key in this.state) {
            if (this.state[key].includeToUrl && this.state[key].urlKey) {
                const queryKey = this.state[key].urlKey;
                if (urlParams.get(queryKey)) {
                    newState[key] = String(urlParams.get(queryKey));
                } else {
                    newState[key] = this.state[key].default;
                }
            }
        }

        this.updateState(newState, false);
    }

    /**
     * Updates the current page URL by adding query params associated with the state object
     */
    updateURLFromState() {
        const urlParams = new URLSearchParams(window.location.search);

        // Set query parameters from the object
        for (let key in this.state) {
            if (!this.state[key].includeToUrl) {
                continue;
            }
            const urlKey = this.state[key].urlKey;
            if (urlKey) {
                if (
                    this.state[key].value === '' ||
                    this.state[key].value === this.state[key].default
                ) {
                    urlParams.delete(urlKey);
                } else {
                    urlParams.set(urlKey, this.state[key].value);
                }
            }
        }
        const newURL = window.location.pathname + '?' + urlParams.toString();

        // Update the URL without triggering a page reload
        history.replaceState({}, '', newURL);
    }

    /**
     * Set initial state for various block features
     */
    setInitialState() {
        // 1. Filter inputs
        this.filtersItemsMap.forEach((item) => {
            const stateValue = this.state[item.queryParam].value ?? '';
            if (item.tag === 'input') {
                if (item.type === 'checkbox') {
                    const inputs = this.container.querySelectorAll(
                        `input[type='checkbox'][name='${item.name}']`,
                    );
                    const checkedInputs = stateValue
                        ? stateValue.split(',')
                        : [];
                    [...inputs].forEach((input) => {
                        input.checked = checkedInputs.includes(input.value);
                    });
                } else if (item.type === 'text') {
                    let input = this.container.querySelector(
                        `input[type='text'][name=${item.name}]`,
                    );
                    input.value = String(stateValue);
                }
            }
        });
        // 2. Calendar - done on init
        // 3. Mixer - done on init
        // 4. Views button
        let view = this.state.view.value;
        if (view === 'list') {
            this.updateViewsButton('dayGridMonth');
        } else {
            this.updateViewsButton('custom');
        }
    }

    /**
     * Modifies the change view button by changing its svg icon and label
     * @param switchToMode
     */
    updateViewsButton(switchToMode = 'dayGridMonth') {
        const button = this.viewButton;
        if (!button) {
            return;
        }

        let currentButtonSpan = button.querySelector(
            `[data-current="true"][data-value]`,
        );
        let neededButtonSpan = null;
        if (switchToMode === 'custom') {
            neededButtonSpan = button.querySelector(`[data-value='list']`);
        } else {
            neededButtonSpan = button.querySelector(`[data-value='calendar']`);
        }

        if (currentButtonSpan && neededButtonSpan) {
            currentButtonSpan.dataset.current = 'false';
            neededButtonSpan.dataset.current = 'true';
        }
    }

    /**
     * Returns string of HTML markup for the passed template with filled values
     * @param {String} template
     * @param {Object} values
     * @return {string|*}
     */
    getTemplateView(template, values = {}) {
        // Trying to find such template in templatesMap
        const templateData = this.templatesMap[template];
        if (!templateData) {
            return '';
        }

        // If no templates block exists on a page
        if (!this.templatesBlock) {
            return '';
        }
        // Getting template's HTML inner block
        const templateBlock = this.templatesBlock.querySelector(
            `[data-role='template'][data-template='${templateData.dataTemplate}']`,
        );
        if (!templateBlock) {
            return;
        }

        const clonedTemplateBlock = templateBlock.cloneNode(true);
        // Filling template with dynamic passed values
        for (const placeholder in values) {
            const placeholderData = templateData.placeholders[placeholder];
            if (!placeholderData) {
                continue;
            }
            const placeholderBlockSelector = placeholderData.selector;
            if (!placeholderBlockSelector) {
                continue;
            }
            const placeholderBlock = clonedTemplateBlock.querySelector(
                placeholderBlockSelector,
            );
            if (!placeholderBlock) {
                continue;
            }
            if (placeholderData.type === 'innerHTML') {
                placeholderBlock.innerHTML = values[placeholder];
            } else if (
                placeholderData.type === 'attribute' &&
                placeholderData.attribute
            ) {
                placeholderBlock.setAttribute(
                    placeholderData.attribute,
                    values[placeholder],
                );
            } else if (placeholderData.type === 'classList') {
                if (placeholderData.clear) {
                    placeholderBlock.className = '';
                }
                placeholderBlock.classList.add(
                    ...values[placeholder].split(' '),
                );
            }
        }

        return clonedTemplateBlock.innerHTML;
    }

    /**
     * Returns a new Date object for the passed date string.
     * Note: Works for the next format only "YYYY-MM-DD".
     * Otherwise, returns null
     * @param {string} dateString
     * @returns {Date|null}
     */
    getDateObject(dateString) {
        if (!dateString) {
            return null;
        }

        const dateParts = dateString.split('-');
        if (dateParts.length === 3) {
            return new Date(
                Number(dateParts[0]),
                Number(dateParts[1]) - 1,
                Number(dateParts[2]),
            );
        }

        return null;
    }

    /**
     * Fires on 'resize' window event
     * @param {Event} event
     */
    resizeEventHandler(event) {
        let windowWidth = window.innerWidth;
        if (this.windowMode === 'mobile') {
            // If we had 'mobile' mode and now window width becomes more than the limit
            if (windowWidth > CalendarA1.MAX_MOBILE_RESOLUTION) {
                // Changing window width mode to 'desktop'
                this.windowMode = 'desktop';
                // Dispatching new event
                this.container.dispatchEvent(
                    new CustomEvent('becameDesktopMode', {
                        bubbles: true,
                    }),
                );
            }
        } else if (this.windowMode === 'desktop') {
            // If we had 'desktop' mode and now window width becomes less than the limit
            if (windowWidth <= CalendarA1.MAX_MOBILE_RESOLUTION) {
                // Changing window width mode to 'mobile'
                this.windowMode = 'mobile';
                // Dispatching new event
                this.container.dispatchEvent(
                    new CustomEvent('becameMobileMode', {
                        bubbles: true,
                    }),
                );
            }
        }
    }

    /**
     * Triggers when a resize event occurs, transitioning the window width from desktop resolution to mobile resolution.
     * @param {Event} event
     */
    becameMobileModeHandler(event) {
        // Do nothing if we have the 'list' view currently
        if (this.state.view.value === 'list') {
            return;
        }
        // Determine new dates range
        let newDatesRange = this.getWeekRange(this.calendar.getDate());

        // Check whether a new slots fetch is needed by comparing current dates range and new ones
        const isNewFetchNeeded = this.checkSlotsDatesRange(newDatesRange);

        // Switching to a new view and modifying its dates range
        this.calendar.changeView('custom', newDatesRange);
        this.updateState({
            view: 'list',
            date_from: this.getFormattedDateString(newDatesRange.start),
            date_to: this.getFormattedDateString(newDatesRange.end),
        });

        this.updateViewsButton('dayGridMonth');

        // Fetch new slots if needed
        if (isNewFetchNeeded) {
            this.fetchSlots();
        }
    }

    /**
     * Triggers when a resize event occurs, transitioning the window width from mobile resolution to desktop resolution.
     * @param {Event} event
     */
    becameDesktopModeHandler(event) {
        let viewFromURL = new URLSearchParams(window.location.search).get(
            'view',
        );
        // Do nothing if current view is the same as from URL
        if (this.state.view.value === viewFromURL) {
            return;
        }

        let newView = viewFromURL === 'list' ? 'dayGridMonth' : 'custom';
        let buttonMode = viewFromURL === 'list' ? 'custom' : 'dayGridMonth';
        let newViewLabel = viewFromURL === 'list' ? 'calendar' : 'list';
        let newDatesRange = this.getMonthRange(this.calendar.getDate());

        // Switching to a new view and modifying its dates range
        this.calendar.changeView(newView, newDatesRange);
        this.updateState({
            view: newViewLabel,
            date_from: this.getFormattedDateString(newDatesRange.start),
            date_to: this.getFormattedDateString(newDatesRange.end),
        });
        // Update view button state according to the current view
        this.updateViewsButton(buttonMode);

        // Fetch new slots if needed
        if (this.checkSlotsDatesRange(newDatesRange)) {
            this.fetchSlots();
        }
    }
}

/**
 * Represents an observer pattern implementation.
 */
class Observer {
    /**
     * Creates an instance of Observer.
     */
    constructor() {
        /**
         * Map containing event subscriptions and their corresponding callbacks.
         * @type {Map<string, Function[]>}
         */
        this.subscribers = new Map();
    }

    /**
     * Subscribe a callback function to an event.
     * @param {string} event      - The event to subscribe to.
     * @param {Function} callback - The callback function to be invoked when the event is triggered.
     */
    subscribe(event, callback) {
        if (!this.subscribers.has(event)) {
            this.subscribers.set(event, []);
        }
        this.subscribers.get(event).push(callback);
    }

    /**
     * Unsubscribe a callback function from an event.
     * @param {string} event      - The event to unsubscribe from.
     * @param {Function} callback - The callback function to be removed from the event's subscribers.
     */
    unsubscribe(event, callback) {
        if (this.subscribers.has(event)) {
            const subscribers = this.subscribers
                .get(event)
                .filter((subscriber) => subscriber !== callback);
            this.subscribers.set(event, subscribers);
        }
    }

    /**
     * Notify subscribers of an event with provided data.
     * @param {string} event - The event to notify subscribers of.
     * @param {*} data       - The data to pass to the subscribers' callback functions.
     */
    notify(event, data) {
        if (this.subscribers.has(event)) {
            const subscribers = this.subscribers.get(event);
            subscribers.forEach((subscriber) => subscriber(data));
        }
    }
}

function calendarA1() {
    const calendarBlocks = document.querySelectorAll(
        `[data-role='calendar-block']`,
    );
    calendarBlocks.forEach((block) => {
        new CalendarA1(block);
    });
}

export default calendarA1;
