function sliderImages() {
    if ($('.js-slideshow-a').length > 0) {
        $('.js-slideshow-a').lightGallery({
            selector: '.js-slideshow-img',
            thumbnail: true,
            animateThumb: true,
            showThumbByDefault: true,
            mode: 'lg-slide',
        });
    }
}

export default sliderImages;
