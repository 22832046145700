import { gsap } from 'gsap';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

gsap.registerPlugin(SplitText);
/**
 * Animates the hero section.
 */
function heroAuto() {
    /**
     * Fetches the hero type from data attribute.
     *
     * @type {string}
     */
    const heroType = $('*[data-hero-type]').data('hero-type');

    /**
     * Selects breadcrumbs element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const breadcrumbs = $(`.o-hero-${heroType}__breadcrumbs`);

    /**
     * Selects pretitle element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const pretitle = $(`.o-hero-${heroType}__pretitle`);

    /**
     * Selects title element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const title = $(`.o-hero-${heroType} .a-title`);

    /**
     * Selects regular separator line element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const separatorLine = $(`.o-hero-${heroType}__separator-line`);

    /**
     * Timeline instance for animation.
     *
     * @type {gsap.core.Timeline}
     */
    const tl = gsap.timeline();

    if (breadcrumbs.length) {
        // Animation for breadcrumbs
        tl.fromTo(
            breadcrumbs,
            { opacity: 0 },
            { duration: 1, opacity: 1, ease: 'power4.inOut' },
            '<+=0.4',
        );
    }

    if (pretitle.length) {
        // Animation for pretitle
        tl.fromTo(
            pretitle,
            { autoAlpha: 0, yPercent: 100 },
            { duration: 1, autoAlpha: 1, yPercent: 0, ease: 'power4.inOut' },
            '<',
        );
    }

    if (title.length) {
        // Animation for title
        tl.fromTo(
            title,
            { autoAlpha: 0 },
            { duration: 0.3, autoAlpha: 1, ease: 'power4.inOut' },
            '<',
        );

        const split = new SplitText(title, {
            tag: 'span',
            type: 'words',
            linesClass: 'overflow-hidden leading-[0.9] pb-3',
        });

        // Split words animation
        tl.from(
            split.words,
            {
                duration: 0.8,
                yPercent: 100,
                autoAlpha: 0,
                ease: 'power4.inOut',
                stagger: 0.1,
            },
            '<',
        );
    }

    if (separatorLine.length) {
        // Animation for separator line
        tl.fromTo(
            separatorLine,
            { scaleX: 0 },
            { duration: 1, scaleX: 1, ease: 'power4.inOut' },
            '<+=0.1',
        );
    }
}

export default heroAuto;
