import { gsap } from 'gsap';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

gsap.registerPlugin(SplitText);
/**
 * Animates the hero section.
 */
function hero2() {
    /**
     * Fetches the hero type from data attribute.
     *
     * @type {string}
     */
    const heroType = $('*[data-hero-type]').data('hero-type');

    /**
     * Selects pretitle element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const pretitle = $(`.o-hero-${heroType}__pretitle`);

    /**
     * Selects title element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const title = $(`.o-hero-${heroType} .a-title`);

    /**
     * Selects subtitle content element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const subtitleContent = $(`.o-hero-${heroType}__content-wrapper .a-text`);

    /**
     * Selects regular button element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const regularButton = $(`.o-hero-${heroType}__buttons-repeater .a-btn`);

    /**
     * Selects background hero set element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const bgHeroSet = $(`.o-hero-${heroType} .m-background-set-hero`);

    /**
     * Selects hero image element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const heroImage = $(`.o-hero-${heroType} .a-img`);

    /**
     * Selects hero video element.
     *
     * @type {JQuery<HTMLElement>}
     */
    const heroVideo = $(
        `.o-hero-${heroType} .m-background-set-hero__video-wrapper`,
    );

    /**
     * Timeline instance for animation.
     *
     * @type {gsap.core.Timeline}
     */
    const tl = gsap.timeline();

    // Animation for bgHeroSet
    tl.from(bgHeroSet, {
        duration: 1.4,
        autoAlpha: 0,
        yPercent: 100,
        ease: 'expo.inOut',
    });

    if (heroImage.length) {
        // Animation for heroImage
        tl.from(
            heroImage,
            {
                duration: 1.4,
                yPercent: -80,
                scale: 1.2,
                ease: 'expo.inOut',
            },
            '<',
        );
    }

    if (heroVideo.length) {
        // Animation for heroVideo
        tl.from(
            heroVideo,
            {
                duration: 1.4,
                yPercent: -80,
                scale: 1.2,
                ease: 'expo.inOut',
            },
            '<',
        );
    }

    if (pretitle.length) {
        // Animation for pretitle
        tl.fromTo(
            pretitle,
            { opacity: 0, yPercent: 100 },
            { duration: 1, opacity: 1, yPercent: 0, ease: 'power4.inOut' },
            '<+=0.4',
        );
    }

    if (title.length) {
        // Animation for title
        tl.fromTo(
            title,
            { autoAlpha: 0 },
            { duration: 0.3, autoAlpha: 1, ease: 'power4.inOut' },
            '<',
        );

        const split = new SplitText(title, {
            tag: 'span',
            type: 'words,lines',
            linesClass: 'overflow-hidden',
        });

        // Split words animation
        tl.from(
            split.words,
            {
                duration: 0.8,
                yPercent: 100,
                autoAlpha: 0,
                ease: 'power4.inOut',
                stagger: 0.1,
            },
            '<+=0.6',
        );
    }

    if (subtitleContent.length) {
        // Animation for subtitleContent
        tl.fromTo(
            subtitleContent,
            { opacity: 0 },
            { duration: 0.5, opacity: 1, ease: 'power4.inOut' },
            '<+=0.3',
        );
    }

    if (regularButton.length) {
        // Animation for regularButton
        tl.fromTo(regularButton, { opacity: 0 }, { opacity: 1 }, '<');
    }
}

export default hero2;
