/**
 * Toggle mobile navigation dropdowns.
 *
 * @param {jQuery} $ - The jQuery object.
 */
function toggleMobileNavigationDropdowns($) {
    const selector = '.js-nav-drop';
    const activeClass = 'is-active';
    const linkItemHasChildren = 'a.menu-item-has-children';

    /**
     * Handle click event on menu items with children.
     *
     * @param {Event} e - The click event.
     */
    function handleMenuItemClick(e) {
        e.preventDefault();
        $(this).next(selector).trigger('click');
    }

    /**
     * Toggle mobile navigation dropdown on click.
     */
    function toggleDropdown() {
        // eslint-disable-next-line jquery/no-slide
        $(this)
            .toggleClass(activeClass)
            .next('.m-nav__sub-menu')
            .slideToggle(250);
        $(this)
            .find('.js-plus-toggle')
            .toggleClass('m-nav__plus-toggle--opened');
    }

    // Attach click event to menu items with children
    $(linkItemHasChildren).on('click', handleMenuItemClick);

    // Attach click event to the mobile navigation dropdown
    $(selector).click(toggleDropdown);
}

export default toggleMobileNavigationDropdowns;
