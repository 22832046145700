import Helpers from '/src/global/js/helpers/helpers.js';

function saveInCalendarInit() {
    const calendarLinks = document.querySelectorAll('[data-calendar-event]');

    if (!calendarLinks) {
        return;
    }

    calendarLinks.forEach((el) => {
        const eventData = Helpers.getJsonFromAttr(el.dataset.calendarEvent);

        if (!eventData) {
            return;
        }

        const events = eventData?.events;
        const filename = eventData?.filename?.replace(/[\W]/gi, '-');

        const dates = [];

        if (events) {
            events.forEach((entry) => {
                let startTime = entry.startTime?.slice(0, -3) ?? null;
                let endTime = null;

                if (startTime) {
                    endTime = entry.endTime
                        ? entry.endTime?.slice(0, -3)
                        : startTime;
                }

                const singleEntry = {
                    name: entry.title,
                    startDate: entry.date,
                    timeZone: 'America/New_York',
                    startTime: startTime,
                    endTime: endTime,
                };
                dates.push(singleEntry);
            });
        }

        const config = {
            name: 'BRIC Event',
            dates: dates,
            options: ['Apple', 'Google', 'iCal'],
            timeZone: 'America/New_York',
            listStyle: 'dropdown',
            iCalFileName: filename,
        };

        el.addEventListener('click', function (event) {
            event.preventDefault();

            // the atcb_action is registered on class and events pages
            // eslint-disable-next-line no-undef
            atcb_action(config, el);
        });
    });
}

export default saveInCalendarInit;
