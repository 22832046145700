/**
 * Hamburger
 *
 * Runs the functionality of clicking the hamburger icon in the top nav.
 */

/**
 * Toggle the hamburger functionality.
 *
 * @function
 */
export function hamburger() {
    // Selector for the hamburger icon
    const selector = '.js-hamburger';

    // Class to toggle for active state
    const activeClass = 'is-active';

    // Classes for body and header active states
    const bodyActiveClass = 'h-scroll-lock nav-active';
    const headerActiveClass = 'o-header--hamburger-active';

    // Selector for the mobile menu
    const navMenu = '.js-slide-out';

    // Class to toggle for mobile menu active state
    const navMenuActiveClass = 'm-slide-out--active';

    // Selector for mobile menu body
    const navMenuBodyClass = $('.m-slide-out__body');

    // Selector for the search button
    const headerSearchButton = 'o-header-1__button-search';

    // Selector for the search bar
    const searchBar = 'm-search-bar';

    // Selector for the language button in the mobile menu
    const languageButtonSelector =
        '.m-slide-out__body .a-button-link-icon[href="#language"]';

    /**
     * Toggle classes for hamburger and mobile menu.
     *
     * @private
     */
    const toggleClasses = () => {
        $(selector).toggleClass(activeClass);
        $('.js-body').toggleClass(bodyActiveClass);
        $('.js-header').toggleClass(headerActiveClass);
        $(navMenu).toggleClass(navMenuActiveClass);
        $(`.${headerSearchButton}`).toggleClass(`${headerSearchButton}--anim`);
    };

    /**
     * Close the menu.
     *
     * @private
     */
    const closeMenu = () => {
        toggleClasses();
    };

    /**
     * Click event handler for the hamburger icon.
     *
     * @param {Event} e - The click event.
     */
    $(selector).click((e) => {
        e.preventDefault();
        toggleClasses();

        // Close the search bar if it's active
        if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
            $(selector).parent().siblings(headerSearchButton).trigger('click');
        }
    });

    /**
     * Click event handler for the language button in the mobile menu.
     */
    $(languageButtonSelector).click(closeMenu);

    /**
     * Mouseup event handler to close the hamburger menu when clicking outside.
     *
     * @param {Event} e - The mouseup event.
     */
    $(document).on('mouseup', (e) => {
        if (
            !navMenuBodyClass.is(e.target) &&
            navMenuBodyClass.has(e.target).length === 0 &&
            !$(selector).is(e.target) &&
            $(selector).has(e.target).length === 0 &&
            $(selector).hasClass(activeClass)
        ) {
            // Close the menu
            if ($('.o-header-1__hamburger').hasClass('is-active')) {
                $('.o-header-1__hamburger').trigger('click');
            }
        }
    });
}
